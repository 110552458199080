.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.MuiListItemText-primary {
  font-family: 'Righteous', serif !important;
}

.MuiListItemText-secondary {
  font-family: 'Changa', serif !important;
}

.MuiBottomNavigationAction-label {
  font-family: 'Changa', serif !important;
}

.iphoneBack {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.fourLine {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.oneLine {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 1!important;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.titleHeader {
  display: inline-flex;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: stretch;
  margin-bottom: 5px;
}

div.scrollmenu {
  background-color: #182128;
  overflow: auto;
  white-space: nowrap;
}
div.scrollmenu div {
  display: inline-block;
  text-decoration: none;
}

.highShadow {
  box-shadow: -1px 14px 15px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.smallShadow {
  box-shadow: 0px 7px 20px 0px rgba(0,0,0,0.2) !important;
}

.linedShadowDInfo {
  box-shadow: unset!important;
  border: thin solid #182128 !important;
}

.linedShadowLInfo {
  box-shadow: unset!important;
  border: thin solid #efeeee !important;
}

.linedShadowD {
  box-shadow: unset!important;
  border: thin solid #262e39 !important;
}

.linedShadowL {
  box-shadow: unset!important;
  border: thin solid #FFFFFF !important;
}

.bottomLineD {
  border-bottom: thin solid rgba(24, 33, 40, 0.45) !important;
}

.bottomLineL {
  border-bottom: thin solid rgba(239, 238, 238, 0.57) !important;
}

.tabsRoot {
  min-height: 45px !important;
  height: 45px !important;;
}

.tabRoot {
  min-height: 35px !important;;
  height: 35px !important;;
}

.myTabL.Mui-selected {
  background-color: white;
}

.myTabD.Mui-selected {
  background-color: #262e39;
}

.columnItem {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
}

.fab {
  margin: 0px!important;
  top: auto;
  right: 20px;
  bottom: 20px;
  left: auto;
  position: fixed !important;
}

.fabNews {
  margin: 0px!important;
  top: auto;
  right: 20px;
  bottom: 70px;
  left: auto;
  position: fixed !important;
}

.fabMenu {
  margin: 0px!important;
  top: auto;
  right: 20px;
  bottom: 80px;
  left: auto;
  position: fixed !important;
}

.fabChat {
  text-align: -webkit-center;
  width: 100% !important;
  margin: 0px!important;
  top: auto;
  right: auto;
  bottom: 80px;
  left: auto;
  position: fixed !important;
}

.centerPage ul {
  place-content: center !important;
}

.centerColorL {
  background: linear-gradient(to bottom, #efeeee 50%, #FFFFFF 50%);
}

.centerColorD {
  background: linear-gradient(to bottom, #182128 50%, #262e39 50%);
}

.scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}

.fullScreenDiv > div:first-child {}

.iframe-loading {
  background:url(https://i.gifer.com/origin/ba/ba4eeed06a8f9efb0d7b5ad26f1f8d45_w200.gif) center center no-repeat;
}

body, html {
  overscroll-behavior: none;
  overflow: hidden;
}

div {
  overscroll-behavior: none;
}

main {
  overscroll-behavior: none;
}

.bottomNavigation {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}

.plyr--video {
  height: 100%;
}


.centerText {
  display: flex;
  text-align: center;
  height: 100%;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.centerText30 {
  display: flex;
  text-align: center;
  height: 30%;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #262e39;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #262e39;
}

.fade-outs {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.hiddens {
  opacity: 0;
  display: none;
}